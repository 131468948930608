<template>
  <div>
    <b-card>
      <ValidationObserver ref="owner" v-slot="{ handleSubmit }">
        <b-form class="form-top p-4" @submit.prevent="handleSubmit(formSubmit)">
          <b-row>
            <b-col md="6">
              <input-form
                v-model="owner.translations[0].name"
                :name="$t('owners.nameAr')"
                :label="$t('owners.nameAr')"
                validate="required"
                :disabled="disableStatus"
              />
            </b-col>
            <b-col md="6">
              <input-form
                v-model="owner.translations[1].name"
                :name="$t('owners.nameEn')"
                :label="$t('owners.nameEn')"
                validate="required"
                :disabled="disableStatus"
              />
            </b-col>

            <b-col md="12">
              <cropper-images
                :label="$t('global.coverImage')"
                required
                nameOfImage="image.jpg"
                @cropper-save="
                  owner.silk_image = $event.file ? $event.file : null
                "
                :multi="false"
                :imageUrl="owner.silk_imageUrl"
                :ratio="1"
                :disabled="disableStatus"
              />
            </b-col>
          </b-row>

          <form-btns
            v-if="currentPage != itemPages.view"
            :label="submitLabel"
            :loading="loading"
          />
        </b-form>
      </ValidationObserver>
    </b-card>

    <b-card v-if="currentPage == itemPages.view">
      <horses :ownerId="id" :titleStatus="true" />
    </b-card>
  </div>
</template>
<script>
import { core } from "../../../../config/pluginInit";
import ownerServices from "../services/owners";
import Owner from "../models/Owner";
import Horses from "../../horses/views/Horses.vue";
import { objectToFormData } from "../../../../Utils/helper";
import moduleItemMixin from "../../../../Mixins/moduleItemMixin";

export default {
  mixins: [moduleItemMixin],
  components: { Horses },
  data() {
    return {
      owner: new Owner(),
    };
  },
  methods: {
    async create() {
      const formData = objectToFormData(this.owner);
      ownerServices
        .create(formData)
        .then((response) => {
          this.loading = false;
          core.showSnackbar("success", response.data.message);
          this.lastRoute.name
            ? this.$router.push(this.lastRoute)
            : this.$router.push({ name: "contributor.owners" });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    async update() {
      const formData = objectToFormData(this.owner);
      ownerServices
        .update(this.id, formData)
        .then((response) => {
          this.loading = false;
          core.showSnackbar("success", response.data.message);
          this.lastRoute.name
            ? this.$router.push(this.lastRoute)
            : this.$router.push({ name: "contributor.owners" });
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    core.index();
  },
  created() {
    if (this.id) {
      ownerServices.findOne(this.id).then((response) => {
        this.owner.fillData(response.data.data);
        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  },
};
</script>
