import api from "@/axios";

export default {
  findAll() {
    return api().get("owner");
  },
  findOne(id) {
    return api().get(`admin/owner/${id}`);
  },
  create(data) {
    return api().post("admin/owner/add", data);
  },
  update(id, data) {
    return api().patch(`admin/owner/${id}`, data);
  },
  remove(id) {
    return api().delete(`admin/owner/${id}`);
  },
};
